import React from "react"

function showCoords(event, caption) {
    var x = event.clientX;
    var y = event.clientY;
    var coords = caption+ ": X coords: " + x + ", Y coords: " + y;
    console.log(coords);
  }

const ResultsSquareRectangle = (props) => {
  return <>
<rect  width={props.width} height={props.height} 
 style={{fill:props.fillColour,strokeWidth:"1",stroke:"#888",x:props.x,y:props.y}}
 onClick={(event)=>{showCoords(event, props.caption)}}>
<title>{props.caption}</title>
</rect>
  </>  
}

export default ResultsSquareRectangle 