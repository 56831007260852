import React from "react"
import DashboardStandoutBox from "../components/DashboardStandoutBox"
// import DashboardProjectBox from "../components/DashboardProjectBox"
// import DashboardProjectAttendanceBox  from "../components/DashboardProjectAttendanceBox"
import Sidebar from "../components/Sidebar"
import TopBar from "../components/TopBar"
import ResultsSquare from "../components/ResultsSquare"

const cohortScores = {
  scores: [
    {
      periodStarting: "2019-07-01",
      scoredAttributes: [
        {
          attribute: "Academic progress",
          score: "1"
        },
        {
          attribute: "Agency",
          score: "1"
        },
        {
          attribute: "Creativity",
          score: "3"
        },
        {
          attribute: "Empathy",
          score: "2"
        },
        {
          attribute: "Productivity",
          score: "1"
        },
        {
          attribute: "Reflection",
          score: "2"
        },
        {
          attribute: "Self awareness",
          score: "1"
        },


      ]

    },
    {
      periodStarting: "2019-07-29",
      scoredAttributes: [
        {
          attribute: "Academic progress",
          score: "2"
        },
        {
          attribute: "Agency",
          score: "2"
        },
        {
          attribute: "Creativity",
          score: "4"
        },
        {
          attribute: "Empathy",
          score: "2"
        },
        {
          attribute: "Productivity",
          score: "2"
        },
        {
          attribute: "Reflection",
          score: "2"
        },
        {
          attribute: "Self awareness",
          score: "3"
        },


      ]

    }
  ]
}
// onMouseOver={(event)=>{showCoords(event)}}
// function showCoords(event) {
//   var x = event.clientX;
//   var y = event.clientY;
//   var coords = "X coords: " + x + ", Y coords: " + y;
//   console.log(coords);
// }


const inbox = { count: 3 }
const IndexPage = () => (
  <>

    <div id="wrapper">


      {/* Sidebar */}
      <Sidebar gorole="admin" />

      {/* Content Wrapper */}
      <div id="content-wrapper" className="d-flex flex-column">

        {/* Main Content */}
        <div id="content">

          <TopBar name="Anushka Aggarwal" gorole="Administrator" inbox={inbox} gad
            caption="Dashboard" />

          {/* Begin Page Content */}
          <div className="container-fluid">

            {/* Content Row */}
            <div className="row">

              {/* Earnings (Monthly) Card Example */}

              <DashboardStandoutBox icon="fa-business-time" count="4 (TODO)" caption="Active Projects" />
              <DashboardStandoutBox icon="fa-address-card" count="38 (TODO)" caption="Active Clients" />
              <DashboardStandoutBox icon="fa-user-clock" count="75% (TODO)" caption="Client Attendance Last Week" />
              <DashboardStandoutBox icon="fa-chart-pie" count="62% (TODO)" caption="Practitioner Utilisation This Week" />

            </div>
            <div className="row">
              <ResultsSquare scores={cohortScores} />

            </div>




          </div>
          {/* /.container-fluid */}

        </div>
        {/* End of Main Content */}

        {/* Footer */}
        <footer className="sticky-footer bg-white">
          <div className="container my-auto">
            <div className="copyright text-center my-auto">
              <span>Copyright &copy; GO Results 2019</span>
            </div>
          </div>
        </footer>
        {/* End of Footer */}

      </div>
      {/* End of Content Wrapper */}

    </div>



    <a className="scroll-to-top rounded" href="#page-top">
      <i className="fas fa-angle-up"></i>
    </a>

    {/* Logout Modal*/}
    <div className="modal fade" id="logoutModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">Ready to Leave?</h5>
            <button className="close" type="button" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">Select "Logout" below if you are ready to end your current session.</div>
          <div className="modal-footer">
            <button className="btn btn-secondary" type="button" data-dismiss="modal">Cancel</button>
            <a className="btn btn-primary" href="login.html">Logout</a>
          </div>
        </div>
      </div>
    </div>

    <script src="./vendor/dave/test.js"></script>
    {/* Bootstrap core JavaScript*/}
    <script src="./vendor/jquery/jquery.min.js"></script>
    <script src="./vendor/bootstrap/js/bootstrap.bundle.min.js"></script>

    {/* Core plugin JavaScript*/}
    <script src="./vendor/jquery-easing/jquery.easing.min.js"></script>

    {/* Custom scripts for all pages*/}
    <script src="./js/sb-admin-2.min.js"></script>

    {/* Page level plugins */}
    <script src="./vendor/chart.js/Chart.min.js"></script>

    {/* Page level custom scripts */}
    <script src="./js/demo/chart-area-demo.js"></script>
    <script src="./js/demo/chart-pie-demo.js"></script>
  </>



)

export default IndexPage
