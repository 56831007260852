import React from "react"
import ResultsSquareRectangle from "../components/ResultsSquareRectangle"
const ResultsSquare = (props) => {

  const graphColours = ["#8DA290", "#F69CC4", "#FCF1D8", "#DEE2D9", "#7799CC", "#F2CBBB", "#BEC7B4", "#FDFD95"]
  function getY(value){
    var retval=y;
    y += parseInt(value) * verticalUnit
    return retval;
  }
function getX(scoreIndex){
  return x + (scoreIndex * horizontalUnit)
}
  let x = 0
  let y = 0
  const horizontalUnit = 50
  const verticalUnit = 30

  let scoreIndex = 0
  return <>
    <svg
      // width="400" height="250" 
      width="1200" height="1200"
      style={{ border: "1px solid black" }}>

      {props.scores.scores[scoreIndex].scoredAttributes.map((elem, index) => {
        return (
          <ResultsSquareRectangle
            key={index}
            x={getX(scoreIndex)}
            y={getY(elem.score)}
            width={horizontalUnit} height={elem.score * verticalUnit}
            caption={`Period starting ${props.scores.scores[scoreIndex].periodStarting} ${elem.attribute} (${elem.score})`}
            score={elem.score} fillColour={graphColours[index]} />

        )
      })}

      <text style={{fontSize:"12px"}} x={getX(scoreIndex)}  y={getY(0)} fill="red" transform={`rotate(60 ${getX(scoreIndex)},${getY(0)+10})`}>{props.scores.scores[scoreIndex].periodStarting}</text>

      Sorry, your browser does not support inline SVG.
 </svg>
  </>


}

export default ResultsSquare 